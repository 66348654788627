/**
 * Change event listener for the category select on the blog page
 */

const categorySelect = document.querySelector("#category-select");

categorySelect &&
  categorySelect.addEventListener("change", (e) => {
    e.preventDefault();
    let categorySlug = e.target.value;

    const url = document.location.origin;

    if (e.target.value === "all") {
      window.location.href = `${url}/career-stories/`;
    } else {
      window.location.href = `${url}/category/${categorySlug}`;
    }
  });

/**
 * Click event listener for the category tags in the blog heading
 */

const categoryTags = document.querySelectorAll(".category-tag");

categoryTags &&
  categoryTags.forEach((tag) => {
    tag.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      let categorySlug = e.target.dataset.value;

      const url = document.location.origin;

      window.location.href = `${url}/category/${categorySlug}`;
    });
  });

document.addEventListener("touchstart", () => {}, false);
