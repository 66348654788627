/**
 * Pathway selector functionality
 */

const pathwaySubmit = document.querySelector("#submit_pathway");
const pathwaySelects = document.querySelectorAll(".pathways__select");
const pathwayForm = document.querySelector("#pathway-select-form");

/**
 * Util function to get the elements container
 * @param {node} el
 * @returns the elements container
 */

const getContainer = (el) => el.closest(".select__wrapper");

/**
 * Util function to check if there is an error showing and removes it
 * if the select now has a valid value
 * @param {node} el
 */

const checkErrors = (el) => {
  const wrapper = getContainer(el);
  const error = wrapper.querySelector(".error");

  if (error && el.value !== "") {
    error.remove();
  }
};

/**
 * Util function to generate the errors
 * @param {string} text Then name of the select to show in the error
 * @returns The text to append for the error
 */

const getErrorText = (text) => `<span class='error'>Please select a ${text}</span>`;

/**
 * Util function to get the site url and the discipline query parameter
 * @returns An array with the sites url and the discipline query parameter
 */

const getUrlDetails = () => {
  const url = document.location.origin;
  const urlParams = new URLSearchParams(window.location.search);
  const discipline = urlParams.get("dis");

  return [url, discipline];
};

/**
 * OnChange listener for the Career pathways form to disable the relevant options
 * upon the users choices.
 * Then chacks errors for the event target select
 */

pathwayForm &&
  pathwayForm.addEventListener("change", (e) => {
    e.preventDefault();

    const position = pathwayForm.querySelector("#position");
    const positions = position.querySelectorAll("option");

    const destination = pathwayForm.querySelector("#destination");
    const destinations = destination.querySelectorAll("option");

    if (position.value !== "") {
      destinations.forEach((el) => {
        if (el.value <= position.value) {
          el.disabled = true;
        } else {
          el.disabled = false;
        }
      });
    }

    if (destination.value !== "") {
      positions.forEach((el) => {
        if (el.value >= destination.value) {
          el.disabled = true;
        } else {
          el.disabled = false;
        }
      });
    }

    checkErrors(e.target);
  });

/**
 * Click event listener for the form submit button that checks the values
 * have been selected and if not it will add errors.
 * If all values have been selected it will navigate to the result page adding the
 * releveant query parameters to the url
 */

pathwaySubmit &&
  pathwaySubmit.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();

    let errors = document.querySelectorAll(".error");

    if (errors) {
      errors.forEach((el) => el.remove());
    }

    let discipline = document.querySelector("#disciplines");
    let position = document.querySelector("#position");
    let destination = document.querySelector("#destination");

    if (discipline.value === "") {
      const container = getContainer(discipline);
      container.insertAdjacentHTML("beforeend", getErrorText("discipline"));
    }

    if (position.value === "") {
      const container = getContainer(position);
      container.insertAdjacentHTML("beforeend", getErrorText("position"));
    }

    if (destination.value === "") {
      const container = getContainer(destination);
      container.insertAdjacentHTML("beforeend", getErrorText("destination"));
    }

    if (discipline.value !== "" && position.value !== "" && destination.value !== "") {
      const url = document.location.origin;

      window.location.href = `${url}/map-your-career/?dis=${discipline.value}&pos=${position.value}&des=${destination.value}`;
    }
  });

/**
 * Disables the relevant options on the pathway result page
 * depending on which position and destination is selected
 */

const positionSelect = document.querySelector("#position-pathway");
const destinationSelect = document.querySelector("#destination-pathway");

if (positionSelect && destinationSelect) {
  const positionSelectOptions = positionSelect.querySelectorAll("option");
  const destinationSelectOptions = destinationSelect.querySelectorAll("option");

  destinationSelectOptions.forEach((option) => {
    if (option.value <= positionSelect.value) option.disabled = true;
  });

  positionSelectOptions.forEach((option) => {
    if (option.value >= destinationSelect.value) option.disabled = true;
  });
}

/**
 * Change event listeners for the Pathway selects on the results page refreshes the page and
 * adds the new position or destination to the url
 */

positionSelect &&
  positionSelect.addEventListener("change", (e) => {
    e.preventDefault();
    const [url, discipline] = getUrlDetails();
    let destination = document.querySelector("#destination-pathway").value;
    window.location.href = `${url}/map-your-career/?dis=${discipline}&pos=${e.target.value}&des=${destination}`;
  });

destinationSelect &&
  destinationSelect.addEventListener("change", (e) => {
    e.preventDefault();
    console.log(e.target);
    const [url, discipline] = getUrlDetails();
    let position = document.querySelector("#position-pathway").value;
    window.location.href = `${url}/map-your-career/?dis=${discipline}&pos=${position}&des=${e.target.value}`;
  });

const pathwayTags = document.querySelectorAll(".pathway-tag");

pathwayTags &&
  pathwayTags.forEach((tag) => {
    tag.addEventListener("click", (e) => {
      e.preventDefault();

      const target = e.target.dataset.value;

      const url = document.location.origin;
      window.location.href = `${url}/glossary/?dis=${target}#glossary`;
    });
  });
