/**
 * Acoordions for the career pathway results page on mobile
 */

const accordionTriggers = document.querySelectorAll(".accordion-trigger");
const accordionItems = document.querySelectorAll(".accordion-content");

accordionTriggers &&
  accordionTriggers.forEach((trigger) => {
    trigger.addEventListener("click", (e) => {
      e.preventDefault();

      const content = trigger.nextElementSibling;

      if (trigger.classList.contains("active")) {
        trigger.classList.remove("active");
        content.classList.remove("open");
        content.style.maxHeight = 0;
        content.style.margin = 0;
      } else {
        accordionTriggers.forEach((el) => el.classList.remove("active"));
        accordionItems.forEach((el) => {
          el.classList.remove("open");
          el.style.maxHeight = 0;
        });

        trigger.classList.add("active");
        content.classList.add("open");
        content.style.maxHeight = `${content.scrollHeight}px`;
        content.style.margin = `0 0 24px 0`;
      }
    });
  });
