/**
 *
 * Click event listener for the Levels Tab
 *
 * @param {eventObject} e event object
 *
 * Adds the current class to the releveant Levels tab
 */

const levelSelects = document.querySelectorAll(".levels-panel__tabs-tab");

levelSelects &&
  levelSelects.forEach((tab) => {
    tab.addEventListener("click", (e) => {
      e.preventDefault();

      levelSelects.forEach((tab) => tab.classList.remove("current"));

      tab.classList.add("current");

      const contentPanels = document.querySelectorAll(".levels-panel__content-panel");

      contentPanels.forEach((panel) => {
        let value = panel.dataset.value;

        value === tab.dataset.value ? panel.classList.remove("hidden") : panel.classList.add("hidden");
      });
    });
  });
