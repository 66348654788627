/**
 * Glossary Page scrool functionality
 */

const glossaryTabs = document.querySelectorAll(".glossary__tabs-tab");
const panels = document.querySelectorAll(".glossary-panel");

/**
 * Switches between Category and A-Z tabs
 */

glossaryTabs &&
  glossaryTabs.forEach((tab) => {
    tab.addEventListener("click", (e) => {
      e.preventDefault();

      glossaryTabs.forEach((tab) => tab.classList.remove("active"));

      tab.classList.add("active");
      const panel = document.getElementById(tab.dataset.value);

      panels.forEach((panel) => panel.classList.remove("show"));
      panel.classList.add("show");
    });
  });

/**
 * Util function to scroll the child element to the top of the container view
 *
 * @param {node} parent The parent container to scroll
 * @param {node} child  The element to scroll to
 */

const scrollParentToChild = (parent, child) => {
  parent.classList.add("scrolling");
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();
  const scrollTop = childRect.top - parentRect.top;
  parent.scrollTop += scrollTop;
  setTimeout(() => parent.classList.remove("scrolling"), 1000);
};

/**
 * Handles the glossary selects change event
 *
 * @param {event} e The event object
 * @param {node} panel The container to scroll
 */

const handleGlossarySelect = (e, panel) => {
  const target = e.target.value;
  const category = document.getElementById(target);

  const categoryContainer = category.closest(".glossary__discipline-panel-item");
  console.log(categoryContainer);

  scrollParentToChild(panel, categoryContainer);
  // setTimeout(() => (e.target.value = ""), 500);
};

/**
 * Adds event to Glossary category select
 */

const glossaryCategoryselect = document.querySelector("#glossary-category");
glossaryCategoryselect &&
  glossaryCategoryselect.addEventListener("change", (e) => {
    e.preventDefault();

    const panel = document.querySelector("#glossary__discipline-panel-categories");
    handleGlossarySelect(e, panel);
  });

/**
 * Adds event to Glossary a-z select
 */

const glossaryatozselect = document.querySelector("#glossary-letter");
glossaryatozselect &&
  glossaryatozselect.addEventListener("change", (e) => {
    e.preventDefault();

    const panel = document.querySelector("#glossary__discipline-panel-a-z");
    handleGlossarySelect(e, panel);
  });

/**
 * Adds a click event to all the glossaries related discipline tags
 */

const glossaryRelatedTags = document.querySelectorAll(".glossary-related-tag");

glossaryRelatedTags.forEach((tag) => {
  tag.addEventListener("click", (e) => {
    e.preventDefault();
    const target = e.target.dataset.value;
    const discipline = document.getElementById(target);
    const panel = e.target.closest(".glossary__discipline-panel");
    scrollParentToChild(panel, discipline);
  });
});

/**
 * Scrolls to the glossary panel and the select discipline
 * if the url contains a query parameter
 */

const glossaryPanel = document.querySelector("#glossary__discipline-panel-categories");

if (glossaryPanel) {
  const urlParams = new URLSearchParams(window.location.search);
  const target = urlParams.get("dis");

  if (target) {
    const discipline = glossaryPanel.querySelector(`#cat-${target}`);
    scrollParentToChild(glossaryPanel, discipline);
  }
}

const glossaryDisciplinePanels = document.querySelectorAll(".glossary__discipline-panel");

glossaryDisciplinePanels &&
  glossaryDisciplinePanels.forEach((panel) => {
    panel.addEventListener("scroll", (e) => {
      const selectWrapper = e.target.previousElementSibling;
      const select = selectWrapper.querySelector("select");

      if (select.value !== "" && !e.target.classList.contains("scrolling")) select.value = "";
    });
  });
