const feedbackTrigger = document.querySelector("#feedback-trigger");
const feedbackForm = document.querySelector("#feedback-form");
const feedbackClose = document.querySelector("#feedback-close");

feedbackTrigger &&
  feedbackTrigger.addEventListener("click", () => {
    feedbackForm.classList.add("show");
  });

feedbackClose &&
  feedbackClose.addEventListener("click", () => {
    feedbackForm.classList.remove("show");
    feedbackForm.classList.add("fade-down");

    setTimeout(() => {
      feedbackForm.classList.remove("fade-down");
    }, 300);
  });
