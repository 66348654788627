/**
 * Show the nav logo upon scroll on the homepage
 */

const homePage = document.querySelector("#home-page");

if (homePage) {
  window.addEventListener("scroll", () => {
    const logo = document.querySelector("#header-logo");
    if (window.scrollY > 150) {
      logo.classList.remove("fade");
    } else {
      logo.classList.add("fade");
    }
  });
}
