/*
 * Modules
 */

import "./modules/mobile-nav";
import "./modules/init-swiper";
import "./modules/video";
import "./modules/tabs";
import "./modules/scroll";
import "./modules/pathway";
import "./modules/stories";
import "./modules/glossary";
import "./modules/accordions";
import "./modules/feedback";
import "./modules/competition";
