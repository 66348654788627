const competitionButton = document.querySelector("#competition-button");
const competitionModal = document.querySelector("#competition-modal");

if (competitionButton && competitionModal) {
  competitionButton.addEventListener("click", (e) => {
    e.preventDefault();
    competitionModal.classList.add("show");
  });

  const exit = competitionModal.querySelector("#competition-modal-exit");

  exit.addEventListener("click", (e) => {
    e.preventDefault();
    competitionModal.classList.remove("show");
  });

  const currentSelectWrapper = competitionModal.querySelector(".current-level-competition");
  const futureSelectWrapper = competitionModal.querySelector(".future-level-competition");

  if (currentSelectWrapper && futureSelectWrapper) {
    const currentSelect = currentSelectWrapper.querySelector("select");
    const currentSelectOptions = currentSelect.querySelectorAll("option");
    const futureSelect = futureSelectWrapper.querySelector("select");
    const futureSelectOptions = futureSelect.querySelectorAll("option");

    currentSelect.addEventListener("change", (e) => {
      const value = e.target.value.replace("Level ", "");
      futureSelectOptions.forEach((option) => {
        if (option.value.replace("Level ", "") < value) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    });

    futureSelect.addEventListener("change", (e) => {
      const value = e.target.value.replace("Level ", "");
      currentSelectOptions.forEach((option) => {
        if (option.value.replace("Level ", "") >= value) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    });
  }
}

const designRadios = document.querySelectorAll("input[name='design_area_radio']");

if (designRadios.length) {
  designRadios.forEach((radio) => {
    radio.addEventListener("change", (e) => {
      const container = radio.closest("form");

      const otherInputContainer = container.querySelector(".other-input");
      const otherInput = otherInputContainer.querySelector("input[name='input_text']");

      if (e.target.value === "Other") {
        otherInputContainer.style.display = "block";
        otherInput.focus();
        otherInput.required = true;
      } else {
        otherInputContainer.style.display = "none";
        otherInput.value = "";
        otherInput.required = false;
      }
    });
  });
}

const config = { attributes: true, childList: true, subtree: true };

const closeButton = document.querySelector("#close-btn");

if (closeButton) {
  closeButton.addEventListener("click", (e) => {
    competitionModal.classList.remove("show");
  });
}

if (competitionModal) {
  competitionModal.addEventListener("click", (e) => {
    if (e.target === competitionModal) {
      competitionModal.classList.remove("show");
    }
  });

  // Callback function to execute when mutations are observed
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList" && mutation.target.classList.contains("fluentform")) {
        console.log(mutation, mutation.target);
        competitionModal.querySelector("#modal-heading").style.display = "none";
        const form = competitionModal.querySelector(".form");
        form.classList.add("submitted");
        closeButton.parentElement.style.display = "block";
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  observer.observe(competitionModal, config);
}

/**
 * Competition popup
 */

const competitionPopupButton = document.querySelector("#competition-popup-button");
const competitionPopupWrapper = document.querySelector("#competition-popup-wrapper");
const competionPopupExit = document.querySelector("#competition-popup-exit");

if (competitionPopupWrapper) {
  if (localStorage.getItem("popupWasShown") === null) {
    setTimeout(() => {
      competitionPopupWrapper.classList.add("show");
    }, 15000);
    localStorage.setItem("popupWasShown", 1);
  }
}

if (competionPopupExit && competitionPopupWrapper) {
  competionPopupExit.addEventListener("click", (e) => {
    e.preventDefault();
    competitionPopupWrapper.classList.remove("show");
  });
}

if (competitionPopupButton && competitionModal) {
  competitionPopupButton.addEventListener("click", (e) => {
    e.preventDefault();
    competitionPopupWrapper.classList.remove("show");
    competitionModal.classList.add("show");
  });
}
