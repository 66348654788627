/**
 * Mobile nav functionality
 */

const navButton = document.querySelector("#js-nav-trigger");
const closeNavButton = document.querySelector("#js-nav-close");
const mobileMenu = document.querySelector("#mobile-menu");

/**
 * Opens the mobile nav
 */
navButton &&
  navButton.addEventListener("click", (e) => {
    e.preventDefault();
    mobileMenu.style.display = "block";
    setTimeout(() => (mobileMenu.style.transform = "translateX(0)"), 200);
    navButton.setAttribute("aria-hidden", true);
    mobileMenu.setAttribute("aria-hidden", false);
    navButton.setAttribute("aria-expanded", true);
    closeNavButton.setAttribute("aria-hidden", false);
  });

/**
 * Closes the mobile nav
 */

closeNavButton &&
  closeNavButton.addEventListener("click", (e) => {
    e.preventDefault();
    mobileMenu.style.transform = "translateX(100%)";
    navButton.setAttribute("aria-hidden", false);
    mobileMenu.setAttribute("aria-hidden", true);
    navButton.setAttribute("aria-expanded", false);
    closeNavButton.setAttribute("aria-hidden", true);
    setTimeout(() => (mobileMenu.style.display = "none"), 300);
  });

/**
 * Closes the mobile nav on the home page for the Map your career nav item
 */

const careerMapNavItem = mobileMenu.querySelector(".menu-item-431");

careerMapNavItem &&
  careerMapNavItem.addEventListener("click", () => {
    mobileMenu.style.transform = "translateX(100%)";
    navButton.setAttribute("aria-hidden", false);
    mobileMenu.setAttribute("aria-hidden", true);
    navButton.setAttribute("aria-expanded", false);
    closeNavButton.setAttribute("aria-hidden", true);
    setTimeout(() => (mobileMenu.style.display = "none"), 300);
  });
