const video = document.querySelector("#hero-video");
const poster = document.querySelector("#video-poster");

window.onload = (event) => {
  if (video) {
    const player = new Vimeo.Player(video);

    poster &&
      poster.addEventListener("click", () => {
        poster.classList.add("hide");

        setTimeout(() => player.play(), 200);
      });

    player.on("ended", function () {
      setTimeout(() => {
        player.element.src = player.element.src;
        poster.classList.remove("hide");
      }, 200);
    });
  }
};
